<template>
  <div>
    <layout-balance-bar />
    <layout-nav-bar />
    <main class="min-h-min">
      <layout-nota-modal
        v-model="changeNetwork"
        title="Something went wrong"
        description="Please make sure you are connected to Binance Smart Chain."
      />
      <slot />
    </main>
    <layout-nota-modal
      v-model="showTCmodal"
      title="Accept Terms & Conditions"
      description="You have to accept the Terms And Conditions to be able to use the platform"
      :cancel="true"
      @proceed="acceptTerms"
      @cancel="$disconnect"
      :btn-disabled="!tcChecked"
    >
      <div class="mx-auto">
        <div class="flex">
          <input type="checkbox" v-model="tcChecked" class="" />
          <label
            for="staking-checkbox"
            class="ml-2 text-sm font-medium text-white dark:text-white"
          >
            I agree with the
            <a
              href="https://notablenft.app/T&CPlatform.pdf"
              class="text-blue-600 hover:underline dark:text-blue-500"
            >
              platform Terms & conditions
            </a>
            .
          </label>
        </div>
      </div>
    </layout-nota-modal>

    <layout-footer />
  </div>
</template>

<script setup>
const tcChecked = ref(false);
const showTCmodal = useTCmodal();
const changeNetwork = useChangeNetwork();
const { $api, $disconnect } = useNuxtApp();
const acceptTerms = async () => {
  if (tcChecked.value) {
    $api.acceptTerms();
  } else {
    $disconnect();
  }
};
useHead({
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon.png",
    },
  ],
  script: [
    {
      children:
        'var _iub = _iub || []; _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"countryDetection":true,"enableLgpd":true,"floatingPreferencesButtonDisplay":"bottom-right","lang":"en","lgpdAppliesGlobally":false,"perPurposeConsent":true,"preferenceCookie":{"expireAfter":180},"siteId":3028062,"whitelabel":false,"cookiePolicyId":46736766,"cookiePolicyUrl":"https://platform.notablenft.app/CookiePolicy(ENG).pdf", "banner":{ "acceptButtonDisplay":true,"closeButtonRejects":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true,"showPurposesToggles":true,"content":"Notable uses technical, analytical and profiling and marketing cookies, including third-party cookies. For technical cookies it is not necessary to acquire your consent: technical cookies are in fact necessary to ensure the proper functioning of the site. By selecting Accept all cookies you will accept all of the above cookies, otherwise you can customize your preferences by clicking on Learn more and customize or continue browsing with the use of technical cookies only by closing this message with the X at the top right.","customizeButtonCaption":"Learn more and customize" }};',
    },
    {
      src: "//cdn.iubenda.com/cs/gpp/stub.js",
      type: "text/javascript",
    },
    {
      src: "//cdn.iubenda.com/cs/iubenda_cs.js",
      type: "text/javascript",
    },
  ],
});
</script>

<style lang="postcss">
@import "../assets/css/tailwind.css";

body {
  @apply dmsans bg-ivory;
}

.web3modal-provider-icon {
  width: 90px;
}

@media screen and (max-width: 768px) {
  .web3modal-provider-icon {
    width: 17vw;
  }
}

.web3modal-provider-icon {
  width: 90px;
}
</style>
